import { ZigThemeOverrides } from "./types";
import dark from "./themes/dark";
import slightlyQuestionable from "./themes/slightlyQuestionable";
import { getMuiTheme } from "./muiTheme";
import { Theme } from "@mui/system";

export const zignalyBaseThemeConfig = dark;

export const getZignalyThemeExport = (
  themeName?: "dark" | "slightlyQuestionable",
  overrides?: ZigThemeOverrides | ZigThemeOverrides[],
): Theme => {
  const theme = themeName === "slightlyQuestionable" ? slightlyQuestionable : dark;
  return getMuiTheme(theme, overrides ? (Array.isArray(overrides) ? overrides : [overrides]) : []);
};

export { GlobalAppStyle } from "./GlobalAppStyle";
