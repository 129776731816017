import { ZigTheme } from "../types";

const slightlyQuestionable: ZigTheme = {
  mode: "light",
  fontFamily: ["Comic Sans MS", "Roboto", "Helvetica", "Arial", "sans-serif"],
  // having this key is important
  // without it overriding would not work
  fontFamilyH1H6: undefined,
  palette: {
    neutral900: "#F9F9E5",
    neutral800: "#EDEAD3",
    neutral750: "#E8E4CF",
    neutral700: "#DADA9D",
    neutral600: "#CACAB5",
    neutral500: "#B5B6A7",
    neutral400: "#8F907D",
    neutral300: "#767665",
    neutral200: "#565645",
    neutral175: "#77652F",
    neutral150: "#454E1B",
    neutral100: "#3E3E37",
    neutral000: "#1E160F",
    secondary: "#AEA553",
    highlighted: "#897D08",
    red: "#33C66C",
    criticalRed: "#33CBA1",
    green: "#D93B69",
    links: "#D93B3E",
    yellow: "#5163A4",
    contrasting: "#0C0B09",
    lightGrey: "#635C50",
    paleBlue: "#66601E",
  },
  backgrounds: {
    body: `#F8F7E6`,
    header: "linear-gradient(269.14deg, #F7F7EF 0%, #EEDED4 100%)",
    neutralBg: "#EFEDDA",
    buttonPrimary: "linear-gradient(289.8deg, #00FF60 0%, #33C66C 100%)",
  },
  imageColorOverride: "",
};

export default slightlyQuestionable;
